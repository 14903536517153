<template>
  <div
    v-if="show"
    class="modal modal__right"
    id="newDepositModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              class="modal__close modal__close--black"
              data-dismiss="modal"
              @click="removeNextDepositForm"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Creating New Deposit Account
          </h5>
        </div>
        <div class="modal__body">
          <form>
            <div v-show="depositForm">
              <div class="col-12 no--padding">
                <div class="form__item">
                  <label class="form__label">Select Customer or Group</label>
                  <select
                    class="select select--lg"
                    required
                    v-model.trim="deposit.accountType"
                  >
                    <option value="" selected default>
                      Select account type
                    </option>
                    <option value="Customers">Customers</option>
                    <option value="Groups">Groups</option>
                  </select>
                </div>
              </div>
              <div class="col-12 no--padding">
                <div class="form__item">
                  <label class="form__label" for="ajax"
                    >Search Customer/Group</label
                  >
                  <div v-if="deposit.accountType === 'Customers'">
                    <multiselect
                      v-model="selectedCustomer"
                      id="ajax"
                      label="customerNameWithID"
                      track-by="customerNameWithID"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="apifetch"
                      @input="getCustomerId"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.customerNameWithID }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                      <span slot="noOptions">Type to display list.</span>
                    </multiselect>
                  </div>
                  <div v-else>
                    <multiselect
                      v-model="selectedCustomer"
                      id="ajax"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="apifetch"
                      @input="getCustomerId"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                      <span slot="noOptions">Type to display list.</span>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-12 no--padding">
                <div class="form__item">
                  <label class="form__label">Select Product</label>
                  <select
                    class="select select--lg"
                    required
                    v-model.trim="deposit.product"
                    @change="getFieldSets(deposit.product)"
                  >
                    <option value="" selected default>
                      Select account type
                    </option>
                    <option
                      v-bind:key="deposit.id"
                      v-for="deposit in deposits"
                      :id="deposit.id"
                      :value="deposit"
                    >
                      {{ deposit.productName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#newDepositModal"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                    :disabled="!isInputFilled"
                    @click="depositAccountFormCreate"
                  >
                    continue
                  </button>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="removeNextDepositForm"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </div>
            <div v-show="nextDepositForm">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Display Name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      v-model="fetchDeposit.savingsAccountName"
                      placeholder="Enter Display Name"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Display ID</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      v-model="deposit.product.sampleIdGenerated"
                      placeholder="Enter Display ID"
                    />
                    <span>ID Template: {{ deposit.product.idTemplate }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Product Type</label>
                    <p
                      class="text--black text--600 text--capital text--regular"
                    >
                      {{ deposit.product.productType }}
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">currency</label>
                    <p
                      class="text--black text--600 text--capital text--regular"
                    >
                      {{ deposit.product.currency }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <p class="text--black text--600 text--capital text--small">
                  {{ deposit.product.productDescription }}
                </p>
              </div>

              <ul id="accordion" class="mb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#accountTerms"
                    aria-expanded="false"
                    aria-controls="accountTerms"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Account Terms
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="accountTerms"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div v-show="fixedTermR" class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label">Interest Rate</label>
                            <div v-if="interestError.length">
                              <div
                                v-for="error in interestError"
                                :key="error.id"
                              >
                                <span
                                  class="text--tiny d-block text--left text--600 mb-2 text--danger"
                                  >{{ error }}</span
                                >
                              </div>
                            </div>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span
                                  class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                                >
                                  %
                                </span>
                              </div>
                              <input
                                type="number"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter interest rate per month"
                                :min="
                                  deposit.product.minInterestRateConstraints
                                "
                                :max="
                                  deposit.product.maxInterestRateConstraints
                                "
                                v-model.number="fetchDeposit.interestRate"
                              />
                            </div>
                            <small
                              class="text--tiny d-block text--right text--600 mt-2"
                              >Min:
                              {{ deposit.product.minInterestRateConstraints }}
                              Max:
                              {{ deposit.product.maxInterestRateConstraints }}
                              &nbsp;&nbsp;|&nbsp;&nbsp;
                              {{ deposit.product.interestChargeMethod }}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-6"
                          v-if="
                            deposit.product.calculationAccountBalance !== null
                          "
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Interest Calculated Using</label
                            >
                            <p
                              class="text--black text--600 text--capital text--regular"
                            >
                              {{ deposit.product.calculationAccountBalance }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="deposit.product.maxTermLength !== 0"
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Maximum term length</label
                            >
                            <p
                              class="text--black text--600 text--capital text--regular"
                            >
                              {{ deposit.product.maxTermLength }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="deposit.product.interestPaymentPeriod !== null"
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Interest paid into account</label
                            >
                            <p
                              class="text--black text--600 text--capital text--regular"
                            >
                              {{ deposit.product.interestPaymentPeriod }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-6"
                          v-if="
                            !['Fixed Deposit', 'FixedDeposit'].includes(
                              deposit.product.productType
                            )
                          "
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Single Withdrawal Limit</label
                            >
                            <input
                              type="text"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                numberIsValid(formattedWithAmount)
                                  ? 'input-required'
                                  : '',
                              ]"
                              min="0"
                              placeholder="Enter Single Withdrawal Limit"
                              :value="formattedWithAmount"
                              @input="numberIsValid(formattedWithAmount)"
                              @blur="formatAndValidateDepositWithdrawal($event)"
                            />
                          </div>
                        </div>

                        <div
                          class="col-6"
                          v-if="
                            !['Fixed Deposit', 'FixedDeposit'].includes(
                              deposit.product.productType
                            )
                          "
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Daily Withdrawal Limit</label
                            >
                            <input
                              type="text"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                numberIsValid(formattedDailyAmount)
                                  ? 'input-required'
                                  : '',
                              ]"
                              min="0"
                              placeholder="Enter Withdrawal Limit"
                              :value="formattedDailyAmount"
                              @input="numberIsValid(formattedDailyAmount)"
                              @blur="formatAndValidateDailyAmount($event)"
                            />
                          </div>
                        </div>

                        <div
                          class="col-12"
                          v-if="
                            !['Fixed Deposit', 'FixedDeposit'].includes(
                              deposit.product.productType
                            )
                          "
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Recommended Deposit Amount</label
                            >
                            <input
                              type="text"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                numberIsValid(formattedAmountPaid)
                                  ? 'input-required'
                                  : '',
                              ]"
                              placeholder="Enter Recommended Deposit"
                              :value="formattedAmountPaid"
                              @input="numberIsValid(formattedAmountPaid)"
                              @blur="fomartAndValidateDepositAmount($event)"
                            />
                            <p class="text-danger" v-if="defDepositAmount">
                              {{ defDepositAmount }}
                            </p>
                          </div>
                        </div>

                        <div
                          class="col-12"
                          v-if="
                            ['Fixed Deposit', 'FixedDeposit'].includes(
                              deposit.product.productType
                            )
                          "
                        >
                          <div class="form__item">
                            <label class="form__label">Opening Balance</label>
                            <input
                              type="text"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                openingBalanceErr ? 'input-required' : '',
                              ]"
                              placeholder="Enter Amount"
                              v-model="fetchDeposit.openingBalance"
                              @blur="fomartAndValidateDepositAmount($event)"
                            />
                          </div>
                          <small
                            class="text--tiny d-block text--right text--600"
                            style="margin-top: -36px; margin-bottom: 4rem"
                            >Min Open Balance:
                            {{ fetchDeposit.minOpeningBalance }} | Max Open
                            Balance:
                            {{ fetchDeposit.maxOpeningBalance }}
                          </small>
                        </div>

                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Branch</label>
                            <multiselect
                              v-model="getAssignedBranchKey"
                              id="ajax"
                              label="branchName"
                              track-by="branchName"
                              placeholder="Type to search branch"
                              open-direction="bottom"
                              :options="branchList"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @input="getAccountOfficer"
                              v-bind:class="{
                                'input-required':
                                  depositErrorForm.assignedBranchKey,
                              }"
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.branchName }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Account Officer</label>
                            <multiselect
                              v-model="getAssignedUserKey"
                              id="ajax"
                              label="name"
                              track-by="name"
                              placeholder="Type to search officer"
                              open-direction="bottom"
                              :options="accountOfficers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="5"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @input="getAssignedUser"
                              v-bind:class="{
                                'input-required':
                                  depositErrorForm.assignedUserKey,
                              }"
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-6" v-if="isSavingPlan">
                          <div class="form__item">
                            <label class="form__label"
                              >Saving Goal Target</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              min="0"
                              placeholder="Enter Recommended Deposit"
                              v-model.number="fetchDeposit.savingsGoalTarget"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="form__item"
                        v-if="deposit.product.interestRateTerms !== null"
                      >
                        <label class="form__label">Interest Rate Terms</label>
                        <p
                          class="text--black text--600 text--capital text--regular"
                        >
                          {{ deposit.product.interestRateTerms }}
                        </p>
                      </div>

                      <div v-show="tierBalance" class="row">
                        <div class="col-12">
                          <div
                            class="form__item p-5 border border-radius"
                            v-for="(balanceTier, counter) in deposit.product
                              .tiers"
                            :key="counter"
                          >
                            <p
                              class="text--primary form__item_text text--regular mb-5"
                            >
                              Tiered Per Balance {{ counter + 1 }}
                            </p>
                            <div class="row">
                              <div class="col-6">
                                <label class="form__label"> From </label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.fromTier"
                                  min="0.00"
                                  readonly
                                />
                              </div>
                              <div class="col-6">
                                <label class="form__label"> To </label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.toTier"
                                  min="0.00"
                                  readonly
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form__item">
                                  <label class="form__label">
                                    Amount for
                                    {{ deposit.product.interestChargeMethod }}
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Enter Amount"
                                    class="input form__input form__input--lg"
                                    v-model.number="
                                      balanceTier.interestTierValue
                                    "
                                    min="0"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-show="tierPeriod" class="row">
                        <div class="col-12">
                          <div
                            class="form__item p-5 border border-radius"
                            v-for="(balanceTier, counter) in deposit.product
                              .tiers"
                            :key="counter"
                          >
                            <p
                              class="text--primary form__item_text text--regular mb-5"
                            >
                              Tiered Per Period {{ counter + 1 }}
                            </p>
                            <div class="row">
                              <div class="col-6">
                                <label class="form__label"> From </label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.fromTier"
                                  min="0.00"
                                  readonly
                                />
                              </div>
                              <div class="col-6">
                                <label class="form__label"> To </label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.toTier"
                                  min="0.00"
                                  readonly
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form__item">
                                  <label class="form__label">
                                    Amount for
                                    {{ deposit.product.interestChargeMethod }}
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Enter Amount"
                                    class="input form__input form__input--lg"
                                    v-model.number="
                                      balanceTier.interestTierValue
                                    "
                                    min="0"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Custom Field -->
                <div
                  v-for="(cFields, index) in fetchDeposit.customFieldItems"
                  :key="cFields.customFieldSetKey"
                  v-show="deposit.product.id"
                  class="modal__accordion"
                >
                  <li
                    v-if="cFields.customFields !== null"
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="'#dynamic_' + cFields.customFieldSetKey"
                    aria-expanded="false"
                    :aria-controls="'dynamic_' + cFields.customFieldSetKey"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      {{ cFields.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="'dynamic_' + cFields.customFieldSetKey"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="(form, i) in cFields.customFields"
                          :key="form.customFieldKey"
                        >
                          <div class="form__item" v-show="isDefault(form)">
                            <label class="form__label">{{
                              form.customFieldName
                            }}</label>
                            <input
                              :type="
                                form.dataType === 'Number'
                                  ? 'number'
                                  : form.dataType === 'Date'
                                  ? 'date'
                                  : form.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="
                                form.dataType !== 'Selection' &&
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              "
                              @input="
                                depositValidateNumber(
                                  $event.target.value,
                                  form.dataType,
                                  index,
                                  i
                                )
                              "
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                              class="input input--right form__input form__input--lg"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                form.customFieldNameErr ? 'input-required' : '',
                              ]"
                              :placeholder="`Enter ${form.customFieldName}`"
                            />
                            <select
                              class="select--lg w-100"
                              v-if="
                                form.dataType === 'Selection' &&
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              "
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                form.customFieldNameErr ? 'input-required' : '',
                              ]"
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                            >
                              <option disabled selected value="null">
                                Select {{ form.customFieldName }}
                              </option>
                              <option
                                v-for="opt in form.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>

                            <!-- User Multi select -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search user"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'user link'"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                form.customFieldNameErr ? 'input-required' : '',
                              ]"
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Group multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="groupName"
                              track-by="groupKey"
                              placeholder="Type to search group"
                              open-direction="bottom"
                              :options="sortedGroups"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                form.dataType.toLowerCase() === 'group link'
                              "
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                form.customFieldNameErr ? 'input-required' : '',
                              ]"
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Customer multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="customerFirstName"
                              track-by="customerKey"
                              placeholder="Type to search customer"
                              open-direction="bottom"
                              :options="sortedCustomers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                form.dataType.toLowerCase() === 'customer link'
                              "
                              :class="[
                                form.customFieldNameErr ? 'input-required' : '',
                              ]"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >
                                <span>{{ option.customerFirstName }}</span>
                                <span>&nbsp;{{ option.customerLastName }}</span>
                              </template>

                              <template slot="option" slot-scope="{ option }">
                                <div class="option__desc">
                                  <span class="option__title">
                                    {{ option.customerFirstName }}&nbsp;
                                  </span>
                                  <span class="option__small">{{
                                    option.customerLastName
                                  }}</span>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#decriptions"
                    aria-expanded="false"
                    aria-controls="decriptions"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Notes
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="decriptions"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Product Descriptions"
                          v-model.trim="fetchDeposit.savingsAccountNotes"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    class="button form__button form__button--lg save-changes"
                    @click.prevent="createDepositAccount"
                    v-else
                  >
                    create deposit account
                  </button>
                  <a
                    @click="removeNextDepositForm()"
                    role="button"
                    data-dismiss="modal"
                    id="closeBtn"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import currencyMixin from "@/mixins/currencyMixin";
import AccessApiService from "@/core/services/access.service";
import branchesService from "@/core/services/organization-service/branches.service";

export default {
  name: "CreateDeposit",
  mixins: [currencyMixin],
  props: {
    show: Boolean,
    close: Function,
  },
  data() {
    return {
      depositForm: true,
      accountOfficers: [],
      allUsers: [],
      allActiveCustomers: [],
      nextDepositForm: false,
      getAssignedUserKey: "",
      getAssignedBranchKey: "",
      deposits: [],
      customers: [],
      loading: false,
      deposit: {
        accountType: "",
        accountName: "",
        product: [],
        customerId: "",
      },
      fetchProduct: {
        customerId: "",
        groupId: "",
        branchId: "",
      },
      depositErrorForm: {
        assignedBranchKey: false,
        assignedUserKey: false,
      },
      selectedCustomer: "",
      formattedAmountPaid: "",
      branchList: [],
      formattedWithAmount: "",
      formattedDailyAmount: "",
      fetchDeposit: {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        assignedBranchKey: "",
        assignedCenterKey: "",
        assignedUserKey: "",
        currencyCode: "",
        interestSettingsKey: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        dailyWaithdrawalLimit: "",
        defaultDepositAmount: "",
        savingsGoalTarget: 0,
        uniqueId: "",
        customFieldItems: "",
        customFields: {},
      },
      fixedTermR: false,
      interestError: [],
      tierBalance: false,
      tierPeriod: false,
      resetFetchForm() {
        this.fetchDeposit = {
          accountHolderKey: "",
          accountHolderType: "",
          accountType: "",
          assignedBranchKey: "",
          assignedCenterKey: "",
          assignedUserKey: "",
          currencyCode: "",
          interestSettingsKey: "",
          savingsAccountName: "",
          savingsAccountNotes: "",
          savingsProductKey: "",
          interestRate: "",
          maxWithdrawalAmount: "",
          dailyWaithdrawalLimit: "",
          defaultOpeningBalance: "",
          maxOpeningBalance: "",
          minOpeningBalance: "",
          openingBalance: "",
          defaultDepositAmount: "",
          savingsGoalTarget: 0,
          uniqueId: "",
          interestError: [],
          accountOfficers: [],
        };
      },
    };
  },
  computed: {
    isSavingPlan() {
      return (
        this.deposit.product.productType === "Saving plan" ||
        this.deposit.product.productType === "Savings plan" ||
        this.deposit.product.productType === "SAVING PLAN" ||
        this.deposit.product.productType === "SAVINGPLAN"
      );
    },
    ...mapState({
      sortedUsers() {
        const userArray = this.allUsers;
        return userArray.sort((a, b) => a.name.localeCompare(b.name));
      },
      sortedCustomers() {
        const userArray = this.allActiveCustomers;
        return userArray.sort((a, b) =>
          `${a.customerFirstName} ${a.customerLastName}`.localeCompare(
            `${b.customerFirstName} ${b.customerLastName}`
          )
        );
      },
    }),
  },
  components: {
    Multiselect,
  },
  methods: {
    removeNextDepositForm() {
      this.depositForm = true;
      this.deposit.product = null;
      this.nextDepositForm = false;
      this.resetDepositForm();
      this.resetFetchForm();
      this.depositErrorForm = {
        assignedBranchKey: false,
        assignedUserKey: false,
      };
      document.getElementById("closeBtn").click();
    },

    isDefault(field) {
      let check = field.customFieldLinks.find(
        (link) => link.entityLinkedKey === this.deposit.product.id
      );
      if (check) {
        return check.isDefault;
      }
      return false;
    },

    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: [],
        };
        AccessApiService.post(
          "ProleanUser/GetUsersWithoutPermission",
          userModel
        ).then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },

    async getAllCustomers() {
      this.allActiveCustomers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageIndex: 1,
          pageSize: 100000,
          filters: [],
          customerStatus: "Active",
        };
        GeneralApiService.post("Customer/customers", userModel).then((resp) => {
          this.allActiveCustomers = resp.data.data.items;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },

    getAccountOfficer(event) {
      this.fetchDeposit.assignedBranchKey = event.branchKey;
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.fetchDeposit.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },

    getGroupAccountOfficer() {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.groupForm.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },

    getCustomerAccountOfficer(key) {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${key}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },

    validateBranchAndOfficer() {
      this.depositErrorForm = {
        assignedBranchKey: false,
        assignedUserKey: false,
      };

      if (
        this.fetchDeposit.assignedUserKey &&
        this.fetchDeposit.assignedBranchKey
      ) {
        return true;
      }

      if (this.fetchDeposit.assignedUserKey) {
        this.depositErrorForm.assignedUserKey = true;
      }
      if (this.fetchDeposit.assignedBranchKey) {
        this.depositErrorForm.assignedBranchKey = true;
      }

      return false;
    },

    depositValidateNumber(num, type, fieldSetIndex, fieldIndex) {
      if (type.toLowerCase() === "number") {
        if (isNaN(parseFloat(num))) {
          this.alertWarning("Invalid input value, Number required.");
          const key =
            this.fetchDeposit.customFieldItems[fieldSetIndex].customFields[
              fieldIndex
            ].customFieldKey;
          this.$refs[key][0].value = 0;
        }
      }
    },

    limitText(count) {
      return `and ${count} other customers`;
    },

    apifetch(query) {
      if (this.deposit.accountType === "Customers") {
        GeneralApiService.get(`Customer/search/${query}`).then((resp) => {
          this.customers = resp.data.data.items;
        });
      } else if (this.deposit.accountType === "Groups") {
        GeneralApiService.get(`Customer/search/${query}/group`).then((resp) => {
          this.customers = resp.data.data.groups;
          this.groups = resp.data.data.customers;
        });
      }
    },

    createDepositAccount(e) {
      e.preventDefault();
      if (!this.validateBranchAndOfficer()) {
        this.alertWarning("Branch and Account officer is required.");
        return;
      }
      this.fetchDeposit.uniqueId = this.deposit.product.sampleIdGenerated;
      this.fetchDeposit.customFields.customFieldValues = [];
      let cForm = [];
      let isValidate = false;
      if (this.fetchDeposit.customFieldItems.length > 0) {
        this.fetchDeposit.customFieldItems.forEach((formSet) => {
          formSet.customFields.forEach((form) => {
            let key = form.customFieldKey;
            let value = this.$refs[key][0].value;

            if (
              ["customer link", "group link", "user link"].includes(
                form.dataType.toLowerCase()
              ) &&
              value.length == 0
            ) {
              value = "";
            }
            if (form.dataType.toLowerCase() === "checkbox") {
              value = this.$refs[key][0].checked;
            }

            let required = form.customFieldLinks.find(
              (item) => item.entityLinkedKey === this.deposit.product.id
            );
            if (!value && required && required.isRequired) {
              this.alertWarning(`Some custom field value are required`);
              isValidate = true;
              form.customFieldNameErr = true;
              return;
            } else {
              form.customFieldNameErr = false;
            }

            if (this.$refs[key].length && this.$refs[key][0].value && value) {
              const formValObj = {
                customFieldSetGroupIndex: 0,
                indexInList: 0,
                customFieldKey: key,
                value:
                  value.userKey || value.customerKey || value.groupKey || value,
              };
              cForm.push({ ...formValObj });
            } else {
              const formValObj = {
                customFieldSetGroupIndex: 0,
                indexInList: 0,
                customFieldKey: key,
                value: "",
              };
              cForm.push({ ...formValObj });
            }
            this.fetchDeposit.customFields.customFieldValues = cForm;
          });
        });
      }
      if (isValidate) {
        return;
      }
      this.fetchDeposit.customFields.url = "";
      this.fetchDeposit.customFields.entityKey = "";
      this.fetchDeposit.customFields.entityTypeKey = this.deposit.product.id;

      if (
        this.fetchDeposit.accountType !== "Fixed Deposit" ||
        this.fetchDeposit.accountType !== "FixedDeposit"
      ) {
        this.loading = true;
        if (
          this.fetchDeposit.accountType.toLowerCase() == "settlement account"
        ) {
          this.fetchDeposit.recommendedDepositAmount =
            this.fetchDeposit.defaultDepositAmount;
        }
        ApiService.post(
          "DepositAccount/CreateDepositAccount",
          this.fetchDeposit
        )
          .then((resp) => {
            this.loading = false;
            this.$emit("alertSuccess", "Deposit Account Created Successfully");
            document.getElementById("closeBtn").click();
            this.deposit.accountType = "";
            this.deposit.accountName = "";
            this.deposit.product = null;
            this.depositErrorForm = {
              assignedBranchKey: false,
              assignedUserKey: false,
            };
            this.viewRoute(resp.data.message);
          })
          .catch((e) => {
            this.loading = false;
            this.$emit("alertError", e.response.data.message);
          });
      } else {
        if (this.numberIsValidFD()) {
          return;
        }
        this.interestError = [];
        this.fetchDeposit.savingsGoalTarget = 0;
        if (
          this.fetchDeposit.interestRate <
            this.deposit.product.minInterestRateConstraints ||
          this.fetchDeposit.interestRate >
            this.deposit.product.maxInterestRateConstraints
        ) {
          this.$emit(
            "alertError",
            `Interest rate must be between ${this.deposit.product.minInterestRateConstraints} and ${this.deposit.product.maxInterestRateConstraints}`
          );
          this.interestError.push(
            `Interest must be between ${this.deposit.product.minInterestRateConstraints} and ${this.deposit.product.maxInterestRateConstraints}`
          );
        }
        if (!this.interestError.length) {
          this.loading = true;
          ApiService.post(
            "DepositAccount/CreateDepositAccount",
            this.fetchDeposit
          )
            .then((resp) => {
              this.loading = false;
              this.$emit(
                "alertSuccess",
                "Deposit Account Created Successfully"
              );
              document.getElementById("closeBtn").click();
              this.deposit.accountType = "";
              this.deposit.accountName = "";
              this.deposit.product = [];
              this.viewRoute(resp.data.message);
            })
            .catch((e) => {
              this.loading = false;
              this.$emit("alertError", e.response.data.message);
            });
        }
      }
    },

    getCustomerId() {
      this.deposits = [];
      this.deposit.customerId = this.selectedCustomer.customerKey;
      this.fetchProduct.customerId = this.selectedCustomer.customerKey;
      this.fetchProduct.branchId = this.selectedCustomer.assignedBranchKey;
      this.fetchProduct.groupId = this.selectedCustomer.groupKey;
      ApiService.post(
        "DepositAdministration/FetchDepositProductsByCustomerOrGroup",
        this.fetchProduct
      )
        .then((resp) => {
          this.deposits = resp.data.data;
        })
        .catch((error) => {
          this.$emit("alertError", error.response.data.message);
        });
    },

    depositAccountFormCreate() {
      this.depositForm = false;
      this.nextDepositForm = true;

      if (this.deposit.product.interestRateTerms === "Fixed") {
        this.fixedTermR = true;
      } else if (
        this.deposit.product.interestRateTerms === "Tier per balance" ||
        this.deposit.product.interestRateTerms === "Tier Per Balance"
      ) {
        this.tierBalance = true;
      } else if (
        this.deposit.product.interestRateTerms === "Tier per period" ||
        this.deposit.product.interestRateTerms === "Tier Per Period"
      ) {
        this.tierPeriod = true;
      } else {
        this.fixedTermR = false;
        this.tierBalance = false;
        this.tierPeriod = false;
      }
      this.fetchDeposit.accountHolderKey =
        this.selectedCustomer.customerKey || this.selectedCustomer.groupKey;
      this.fetchDeposit.accountHolderType = this.deposit.accountType;
      this.fetchDeposit.accountType = this.deposit.product.productType;
      // this.fetchDeposit.assignedBranchKey =
      //   this.selectedCustomer.assignedBranchKey;
      this.fetchDeposit.assignedBranchKey = "";
      this.fetchDeposit.assignedCenterKey =
        this.selectedCustomer.assignedCentreKey;
      this.fetchDeposit.assignedUserKey = "";
      this.fetchDeposit.currencyCode = this.deposit.product.currency;
      this.fetchDeposit.interestSettingsKey =
        this.deposit.product.interestSavingsKey;
      this.fetchDeposit.savingsAccountName = this.deposit.product.productName;
      this.fetchDeposit.savingsProductKey = this.deposit.product.id;
      this.fetchDeposit.maxWithdrawalAmount =
        this.deposit.product.maxWithdrawalAmount;
      this.fetchDeposit.dailyWaithdrawalLimit =
        this.deposit.product.dailyWithdrawalLimit;
      (this.fetchDeposit.openingBalance =
        this.deposit.product.minOpeningBalance),
        (this.fetchDeposit.maxOpeningBalance =
          this.deposit.product.maxOpeningBalance),
        (this.fetchDeposit.minOpeningBalance =
          this.deposit.product.minOpeningBalance),
        (this.fetchDeposit.defaultDepositAmount =
          this.deposit.product.defaultDepositAmount);
      this.formattedWithAmount = this.inputCurrencyFormat(
        this.deposit.product.maxWithdrawalAmount
      );
      this.formattedDailyAmount = this.inputCurrencyFormat(
        this.deposit.product.dailyWithdrawalLimit
      );
      this.formattedAmountPaid = this.inputCurrencyFormat(
        this.deposit.product.defaultDepositAmount
      );
    },

    viewRoute(data) {
      this.$router.push({ path: `/deposit-account/${data}` });
    },

    isInputFilled() {
      return (
        this.selectedCustomer &&
        this.deposit.product &&
        this.deposit.accountType
      );
    },

    resetDepositForm() {
      this.deposit = {
        accountType: "",
        accountName: "",
        customerId: "",
        productName: "",
        product: [],
      };
      this.selectedCustomer = {};
    },

    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },

    numberIsValidFD() {
      if (!this.fetchDeposit.openingBalance) {
        this.alertWarning("Opening balance is required.");
        return true;
      }
      if (
        !isNaN(parseFloat(this.fetchDeposit.openingBalance)) &&
        isFinite(this.fetchDeposit.openingBalance)
      ) {
        if (
          parseFloat(this.fetchDeposit.openingBalance) <
          this.fetchDeposit.minOpeningBalance
        ) {
          this.alertWarning(
            "Opening Balance Amount cannot be less than the minimum opening balance value."
          );
          // this.fetchDeposit.openingBalance = this.fetchDeposit.minOpeningBalance;
          this.openingBalanceErr = true;
          return true;
        } else if (
          parseFloat(this.fetchDeposit.openingBalance) >
          this.fetchDeposit.maxOpeningBalance
        ) {
          this.alertWarning(
            "Opening Balance Amount cannot be greater than the maximum opening balance value."
          );
          // this.fetchDeposit.openingBalance = this.fetchDeposit.maxOpeningBalance;
          this.openingBalanceErr = true;
          return true;
        } else {
          this.openingBalanceErr = false;
          return false;
        }
      } else {
        this.alertWarning("Invalid input type, number required.");
        // this.fetchDeposit.openingBalance = this.fetchDeposit.minOpeningBalance;
        this.openingBalanceErr = true;
        return true;
      }
    },

    fomartAndValidateDepositAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.fetchDeposit.defaultDepositAmount = amtPaid;
        this.formattedAmountPaid = amountInputted;
        return;
      }
      this.fetchDeposit.defaultDepositAmount = 0;
      this.formattedAmountPaid = "";
    },

    formatAndValidateDepositWithdrawal(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.fetchDeposit.maxWithdrawalAmount = amtPaid;
        this.formattedWithAmount = amountInputted;
        return;
      }
      this.fetchDeposit.defaultDepositAmount = 0;
      this.formattedWithAmount = "";
    },

    formatAndValidateDailyAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.fetchDeposit.dailyWaithdrawalLimit = amtPaid;
        this.formattedDailyAmount = amountInputted;
        return;
      }
      this.fetchDeposit.defaultDepositAmount = 0;
      this.formattedDailyAmount = "";
    },

    getAssignedUser(event) {
      this.fetchDeposit.assignedUserKey = event.userKey;
    },

    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          sortColumn: "",
          isDescending: false,
          pageIndex: 1,
          pageSize: 100,
        });
        this.branchList = res.data.data.items;
        this.loading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },

    getFieldSets(usage) {
      this.fetchDeposit.customFieldItems = [];
      const p = {
        customFieldSetType: "DepositAccounts",
        entityLinkedKey: usage.id,
      };
      GeneralApiService.post("CustomFields/FieldSets/FieldSetType", p)
        .then((res) => res.data.data)
        .then((res) => {
          this.fetchDeposit.customFieldItems = res.items;
        })
        .catch(() => {});
    },
  },

  watch: {
    show(change) {
      if (change) {
        this.getBranches();
        this.getAllCustomers();
        this.getAllUsers();
      }
    },
  },
};
</script>
